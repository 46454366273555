import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { LocalStorageJwtService } from '../services/token-storage';
import { ApiURL } from '../tokens';

export const tokenInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const apiUrl = inject(ApiURL);
  const storage = inject(LocalStorageJwtService);

  const token = storage.getItemSync();

  let newRequest = req;
  if (token) {
    const isValid = token.isValid();
    const isApiReq = req.url.includes(apiUrl);

    if (isValid && isApiReq) {
      newRequest = req.clone({
        headers: req.headers.append(
          'Authorization',
          `Bearer ${token.toString()}`,
        ),
      });
    }
  }

  return next(newRequest);
};

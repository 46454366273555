import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  inject,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import {
  provideRouter,
  Router,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  provideClientHydration,
  withNoHttpTransferCache,
} from '@angular/platform-browser';
import { provideServiceWorker, SwUpdate } from '@angular/service-worker';
import { WA_WINDOW } from '@ng-web-apis/common';
import * as Sentry from '@sentry/angular';
import { version } from '../../package.json';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { tokenInterceptor } from './core/interceptors/token.interceptor';
import {
  AdmefyHomeURL,
  AnalyticsUrlProxy,
  ApiURL,
  WebSocketsURL,
} from './core/tokens';

const enableServiceWorker = () => {
  return false;
  // return !isDevMode() &&
  // typeof navigator !== 'undefined' &&
  // 'serviceWorker' in navigator;
};

const checkForUpdates = (
  swUpdate: SwUpdate,
  win = inject(WA_WINDOW),
): (() => Promise<any>) => {
  return (): Promise<void> =>
    new Promise(async (resolve) => {
      if ('serviceWorker' in win.navigator && swUpdate.isEnabled) {
        const isNewVersion = await swUpdate.checkForUpdate().catch(() => false);

        swUpdate.versionUpdates.subscribe((version) => {
          if (version.type === 'VERSION_DETECTED') {
            win.location.reload();
          }
        });

        if (isNewVersion) {
          const isNewVersionActivated = await swUpdate
            .activateUpdate()
            .catch(() => false);
          // Check if the new version is activated and reload the app if it is
          if (isNewVersionActivated) win.location.reload();
        }
      }

      resolve();
    });
};

Sentry.init({
  dsn: 'https://8c8f942be37e5551ba0c82b76dd31f42@o295403.ingest.us.sentry.io/4507656259174400',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  release: `player@${version}`,
  environment: !isDevMode() ? 'production' : 'development',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/admefy\.com/,
    /^https:\/\/devel\.admefy\.com/,
    /^https:\/\/player\.admefy\.com/,
    /^https:\/\/player\.devel\.admefy\.com/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: AdmefyHomeURL,
      useValue: environment.websiteUrl,
    },
    {
      provide: ApiURL,
      useValue: environment.apiUrl,
    },
    {
      provide: WebSocketsURL,
      useValue: environment.wsAPIUrl,
    },
    {
      provide: AnalyticsUrlProxy,
      useValue: environment.analyticsProxyUrl,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: checkForUpdates,
      //useValue: () => {},
      multi: true,
      deps: [SwUpdate, WA_WINDOW, Sentry.TraceService],
      //deps: [Sentry.TraceService],
    },
    provideClientHydration(withNoHttpTransferCache()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withViewTransitions(),
      withComponentInputBinding(),
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
      }),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload',
      }),
    ),
    provideHttpClient(withInterceptors([tokenInterceptor])),
    provideServiceWorker('ngsw-worker.js', {
      enabled: enableServiceWorker(),
      registrationStrategy: 'registerImmediately',
    }),
  ],
};

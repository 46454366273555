import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { catchError, of } from 'rxjs';
import { AppStore } from './core/+store/app.store';
import { DisplayService } from './core/services/display/display.service';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./routes/player/player.component').then((m) => m.PlayerComponent),
    canMatch: [
      () => {
        const store = inject(AppStore);
        return !!store.token();
      },
    ],
    resolve: {
      display: () => {
        const displayService = inject(DisplayService);

        return displayService.getDisplay().pipe(catchError(() => of(null)));
      },
    },
  },
  {
    path: '',
    loadComponent: () =>
      import('./routes/link/link.component').then((m) => m.LinkComponent),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
